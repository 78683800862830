import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Highlight
} from '@chakra-ui/react';
import { useContext } from "react";
import { InboundSalesContext } from "../InboundSales";
export { Head } from "../seo";

export default function Municipalities() {
    const { onOpen } = useContext(InboundSalesContext);
    return (
        <>
            <Box bg="gray.50">
                <Container maxW={'3xl'} >
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize="md"
                        as="h1"
                        bg={'blue.50'}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                            Parkify for Municipalities
                        </Heading>
                        <Heading
                            fontWeight={800}
                            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                            as="h2"
                        >
                            <Highlight
                                query='On-street'
                                styles={{ color: "purple.500" }}
                            >
                                On-street parking
                            </Highlight>
                        </Heading>
                        <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Please contact us for more information about how Parkify works with on-street parking. 
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                        >
                            <Button
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="purple"
                                onClick={onOpen}
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}